import { type ClassValue, clsx } from 'clsx'
import { FieldErrors } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { assert } from './assertions'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function exhaustiveGuard(val: never, message?: string): never {
  throw new Error(message ?? `Unhandled case. Value should be never but was ${val}`)
}

export function raise<T>(message: string): T {
  throw new Error(message)
}

export function parseRootErrorMessage(errors: FieldErrors) {
  return Object.keys(errors.root || []).reduce((acc: string | undefined, rootErrorFieldName) => {
    const errorMessage = errors.root![rootErrorFieldName].message
    if (acc === undefined) {
      acc = errorMessage
    } else {
      acc = `${acc}. ${errorMessage}.`
    }

    return acc
  }, undefined)
}

type KeyTypes = string | number | symbol
type IndexKeys<T> = keyof {
  [K in keyof T as T[K] extends KeyTypes ? K : never]: T[K]
}

export function indexByFunc<T>(collection: Iterable<T>, indexBy: (t: T) => KeyTypes) {
  const ret = {} as Record<KeyTypes, T>

  for (const item of collection) {
    const key = indexBy(item)
    assert(ret[key] === undefined, `Attempted to index by a non-unique value: ${String(key)}`)

    ret[key] = item
  }

  return ret
}

export function index<T, K extends IndexKeys<T>>(collection: Iterable<T>, indexBy: K) {
  return indexByFunc(collection, (t) => t[indexBy] as KeyTypes)
}

export function groupByFunc<T>(collection: Iterable<T>, groupBy: (t: T) => KeyTypes): Record<KeyTypes, [T, ...T[]]> {
  const ret = {} as Record<KeyTypes, [T, ...T[]]>

  for (const item of collection) {
    const key = groupBy(item)
    if (ret[key]) {
      ret[key].push(item)
    } else {
      ret[key] = [item]
    }
  }

  return ret
}

export function groupBy<T, K extends IndexKeys<T>>(collection: Iterable<T>, indexBy: K): Record<KeyTypes, [T, ...T[]]> {
  return groupByFunc(collection, (t) => t[indexBy] as KeyTypes)
}
