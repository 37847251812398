import { useEffect } from 'react'
import { ToastMessage } from 'remix-toast'
import { toast } from 'sonner'
import { exhaustiveGuard } from '~/lib/utils'

const useToast = (toastMessage: ToastMessage | undefined) => {
  useEffect(() => {
    if (!toastMessage) {
      return
    }

    switch (toastMessage.type) {
      case 'error':
        toast.error(toastMessage.message, {
          description: toastMessage.description,
        })
        break
      case 'info':
        toast.info(toastMessage.message, {
          description: toastMessage.description,
        })
        break
      case 'success':
        toast.success(toastMessage.message, {
          description: toastMessage.description,
        })
        break
      case 'warning':
        toast.warning(toastMessage.message, {
          description: toastMessage.description,
        })
        break
      default:
        exhaustiveGuard(toastMessage.type, `Unhandled toast type: ${toastMessage.type}`)
    }
  }, [toastMessage])
}

export { toast, useToast }
